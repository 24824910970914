import { accordionToggle } from "./components/accordion";
import { forms } from "./components/forms";
import { burgerBtn, classesHeader, getTheme, navbarScrollBehavior, optionsMenu } from "./components/header";
import { longTextAccordion, longTextChapters, longTextSticky } from "./components/long-text";
import { pricingList } from "./components/pricing-list";
import { handleVideo } from "./components/text-with-media-video-link";
import { animations } from "./components/animations";
import { footerBehavior } from "./components/footer";
import { heroMainBehavior } from "./components/hero-main";
import { handleCookies } from "./components/cookie-bar";

window.addEventListener("load", () => {
    navbarScrollBehavior();
    optionsMenu();
    burgerBtn();
    classesHeader();
    getTheme();
    handleVideo();
    accordionToggle();
    longTextChapters();
    longTextAccordion();
    longTextSticky();
    pricingList();
    forms();
    animations();
    footerBehavior();
    heroMainBehavior();
    handleCookies();
});
