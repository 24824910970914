import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger)

export const navbarScrollBehavior = () => {
    const header = document.querySelector(".header");
    const nav = document.querySelector(".header__nav");

    if (header) {
        gsap.to(nav, {
            scrollTrigger: {
                // markers: true,
                trigger: nav,
                start: "top 33px",
                end: "bottom 33px",
                onEnter: () => {
                    header.classList.add("--active");
                    header.classList.remove("--fixed");
                },
                onEnterBack: () => {
                    header.classList.add("--active");
                    header.classList.remove("--fixed");
                },
                onLeave: () => {
                    header.classList.remove("--active");
                    header.classList.add("--fixed");

                },
                onLeaveBack: () => {
                    header.classList.remove("--active");
                    header.classList.add("--fixed");
                }
            }
        })
    }
}

export const optionsMenuDesktop = () => {
    const header = document.querySelector(".header");
    const headerNav = document.querySelector(".header__nav");
    const options = document.querySelectorAll(".header__option");
    const desktop = document.querySelector(".header__desktop");
    const originalHeaderHeight = headerNav.clientHeight;

    if (headerNav && options && desktop) {
        options.forEach((option) => {
            option.addEventListener("mouseover", () => {
                options.forEach((otherOption) => {
                    if (otherOption !== option) {
                        otherOption.classList.add("--inactive");
                    }
                });

                option.classList.remove("--inactive");
                option.classList.add("--active");

                const submenu = option.querySelector(".submenu");
                if (submenu) {
                    const submenuHeight = submenu.clientHeight;
                    headerNav.style.height = originalHeaderHeight + submenuHeight + "px";
                } else {
                    headerNav.style.height = originalHeaderHeight + "px";
                }
            });

            option.addEventListener("mouseleave", () => {
                options.forEach((otherOption) => {
                    otherOption.classList.remove("--inactive");
                });

                option.classList.remove("--active");

                headerNav.style.height = originalHeaderHeight + "px";
            });
        });

        // headerNav.addEventListener("mouseleave", () => {
        //     header.classList.contains("--fixed") ? headerNav.style.height = "max-content" : headerNav.style.height = originalHeaderHeight + "px";
        // })

        // window.addEventListener("scroll", () => {
        //     header.classList.contains("--fixed") ? headerNav.style.height = "max-content" : null;
        // })
    }
};

export const optionsMenuMobile = () => {
    const headerNav = document.querySelector(".header__mobile");
    const options = document.querySelectorAll(".header__option");
    let activeOption = null;

    if (headerNav && options) {
        options.forEach((option) => {
            option.addEventListener("click", () => {
                if (option === activeOption) {
                    option.classList.remove("--active");
                    option.classList.remove("--inactive");
                    options.forEach((otherOption) => {
                        otherOption.classList.remove("--active");
                        otherOption.classList.remove("--inactive");
                    })
                    option.style.marginBottom = 0;
                    activeOption = null;
                } else {
                    options.forEach((otherOption) => {
                        if (otherOption !== option) {
                            otherOption.classList.remove("--active");
                            otherOption.classList.add("--inactive");
                            otherOption.style.marginBottom = 0;
                        }
                    });

                    option.classList.add("--active");
                    option.classList.remove("--inactive");
                    activeOption = option;

                    const submenu = option.querySelector(".submenu");
                    if (submenu) {
                        const submenuHeight = submenu.clientHeight;
                        option.style.marginBottom = submenuHeight + "px";
                    } else {
                        option.style.marginBottom = 0;
                    }
                }
            });
        });
    }
};


export const optionsMenu = () => {
    const viewportWidth = window.innerWidth;

    if (viewportWidth >= 1024) {
        optionsMenuDesktop();
    } else {
        optionsMenuMobile();
    }

    window.addEventListener('resize', () => {
        if (viewportWidth >= 1024) {
            optionsMenuDesktop();
        } else {
            optionsMenuMobile();
        }
    })
}

export const burgerBtn = () => {
    const header = document.querySelector(".header");
    const burger = document.querySelector(".header__burger")
    const close = document.querySelector(".header__close")
    const options = document.querySelectorAll(".header__option");

    burger?.addEventListener("click", () => {
        header.classList.add("--open")
    })

    close?.addEventListener("click", () => {
        header.classList.remove("--open")
        options.forEach(option => {
            option.style.marginBottom = 0
        })
    })
}

export const classesHeader = () => {
    window.addEventListener('resize', () => {
        const viewportWidth = window.innerWidth;
        const header = document.querySelector(".header");

        if (viewportWidth >= 1024 && header) {
            header.classList.remove('--open');
            header.classList.add('--active');
        } else if (viewportWidth < 1024 && header) {
            header.classList.remove('--active');
            header.classList.remove('--fixed');
        }
    });
}

export const getTheme = () => {
    const header = document.querySelector('header');
    const headerNav = document.querySelector('.header__nav');
    const headerLogo = document.querySelector('.header__logo');
    const container = document.querySelector('.header__desktop')
    const options = container?.querySelectorAll('.header__option');
    const svgBurger = document.querySelector('.header__burger__svg');
    let siguienteElemento = header.nextElementSibling;

    while (siguienteElemento && siguienteElemento.tagName !== 'SECTION') {
        siguienteElemento = siguienteElemento.nextElementSibling;
    }

    if (siguienteElemento && siguienteElemento.tagName === 'SECTION' && siguienteElemento.classList.contains('t-bluish-teal')) {
        options?.forEach(option => {
            option.style.color = '#F6F0ED'
        })
        headerNav.style.borderColor = '#F6F0ED33'

        if (svgBurger) {
            svgBurger.style.stroke = '#F6F0ED'
        }
    } else {
        options?.forEach(option => {
            option.style.color = '#24323F'
        })
        headerNav.style.borderColor = '#24323F33'
        if (svgBurger) {
            svgBurger.style.stroke = '#24233F'
        }
    }
}
