export const pricingList = () => {
    const leftRadio = document.querySelector(".input-left");
    const rightRadio = document.querySelector(".input-right");
    
    const leftContainer = document.querySelector(".pricing-list__left");
    const rightContainer = document.querySelector(".pricing-list__right");
    
    leftRadio?.addEventListener("change", () => {
        leftContainer.classList.add("active");
        rightContainer.classList.remove("active");
        document.querySelector(".label-left").classList.add("checked");
        document.querySelector(".label-right").classList.remove("checked");
    });
    
    rightRadio?.addEventListener("change", () => {
        rightContainer.classList.add("active");
        leftContainer.classList.remove("active");
        document.querySelector(".label-right").classList.add("checked");
        document.querySelector(".label-left").classList.remove("checked");
    });
}
