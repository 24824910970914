export const forms = () => {
    const form = document.querySelector(".form");
    const submitButton = document.querySelector(".form .btn");

    if(form) {

        // validaciones campos
        const firstNameInput = document.getElementById("first_name");
        firstNameInput?.addEventListener("input", validateFirstName);
        
        const lastNameInput = document.getElementById("last_name");
        lastNameInput?.addEventListener("input", validateLastName);
        
        const emailInput = document.getElementById("email");
        emailInput?.addEventListener("input", validateEmail);
        
        const phoneInput = document.getElementById("phone");
        phoneInput?.addEventListener("input", validatePhone);
        
        const cvInput = document.getElementById("curriculum");
        cvInput?.addEventListener("input", validateCV);
        
        function validateFirstName() {
            const isValid = firstNameInput.value.trim() !== "";
            updateFieldValidation(firstNameInput, isValid);
        }
        
        function validateLastName() {
            const isValid = lastNameInput.value.trim() !== "";
            updateFieldValidation(lastNameInput, isValid);
        }
        
        function validateEmail() {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const isValid = emailRegex.test(emailInput.value.trim());
            updateFieldValidation(emailInput, isValid);
        }
        
        function validatePhone() {
            const phoneRegex = /^\d+$/;
            const isValid = phoneRegex.test(phoneInput.value.trim());
            updateFieldValidation(phoneInput, isValid);
        }
        
        function validateCV() {
            const cvInputValue = cvInput.value.trim().toLowerCase();
            const allowedExtensions = ["pdf", "doc", "docx", "txt", "rtf"];
            const fileName = cvInputValue.slice(((cvInputValue.lastIndexOf(".") - 1) >>> 0) + 2);
            const isValid = cvInputValue !== "" && allowedExtensions.includes(fileName);
            
            updateFieldValidation(cvInput, isValid);
        }
        
        function updateFieldValidation(inputElement, isValid) {
            if (isValid) {
                removeErrorClass(inputElement);
            } else {
                addErrorClass(inputElement);
            }
        }
        
        function addErrorClass(inputElement) {
            const inputContainer = inputElement.closest(".form-input-container");
            inputContainer.classList.add("--error");
        }
        
        function removeErrorClass(inputElement) {
            const inputContainer = inputElement.closest(".form-input-container");
            inputContainer.classList.remove("--error");
        }

        // validacion al submit
        form.addEventListener("submit", (e) => {
            e.preventDefault();
            const firstNameValue = firstNameInput?.value.trim();
            const lastNameValue = lastNameInput?.value.trim();
            const emailValue = emailInput?.value.trim();
            const phoneValue = phoneInput?.value.trim();
            const cvValue = cvInput?.value.trim();
            const inputs = document.querySelectorAll(".form-input-container");
            const inputArray = Array.from(inputs);
            const valueArray = [firstNameInput, lastNameInput, emailInput, phoneInput, cvInput]

            const hasError = inputArray.some((input) => {
                return input.classList.contains("--error");
            });

            valueArray?.forEach((el) => {
                if (el?.value.trim() == "") {
                    const control = el.parentNode
                    const input = control.parentNode
                    input.classList.add("--error")
                }
            })

            if(
                hasError ||
                firstNameValue == "" ||
                lastNameValue == "" ||
                emailValue == "" ||
                phoneValue == "" ||
                cvValue == ""
            ) {
                return
            } else {
                form.submit();
            }
        });
        
        
        // comportamiento del input file
        let spanClick = document.getElementById("spanClick");

        spanClick?.addEventListener("click", () => {
            cvInput.click();
        });

        cvInput?.addEventListener("change", () => {
            if (cvInput.files.length > 0) {
                const fileName = cvInput.files[0].name;
                spanClick.querySelector(".text-big-p").textContent = fileName;
            } else {
                spanClick.querySelector(".text-big-p").textContent = "Attach";
            }
        });

        // font de los inputs dependiendo el viewport
        const inputElement = form.querySelectorAll(".form-input");

        inputElement.forEach((el) => {
            el.addEventListener("input", function () {
                el.style.fontFamily = "Inter";

                function fontSize() {
                    const viewportWidth = window.innerWidth;
                
                    if (viewportWidth < 745 && el) {
                        el.style.fontSize = "14px"
                        el.style.fontWeight = "400"
                        el.style.lineHeight = "1.5"
                        el.style.letterSpacing = "-0.14px"
                    } else if (viewportWidth < 2560 && viewportWidth >= 745 && el) {
                        el.style.fontSize = "16px"
                        el.style.fontWeight = "400"
                        el.style.lineHeight = "1.5"
                        el.style.letterSpacing = "-0.16px"
                    } else if (viewportWidth >= 2560 && el) {
                        el.style.fontSize = "32px"
                        el.style.fontWeight = "400"
                        el.style.lineHeight = "1.5"
                        el.style.letterSpacing = "-0.32px"
                    }
                }

                fontSize()

                window.addEventListener('resize', () => {
                    fontSize()
                });
            });
        })
    }
}
