export const handleCookies = () => {
    const formId = "cookieBarForm";
    const cookieBarForm = document.getElementById(formId);
    const rejectBtn = document.querySelector(`#${formId} #rejectBtn`);

    rejectBtn.addEventListener("click", () => {
        const checkboxes = document.querySelectorAll(`#${formId} li input[type="checkbox"]`);
        checkboxes.forEach((checkbox) => {
            if (!checkbox.disabled) {
                checkbox.checked = false;
            }
        });
        cookieBarForm.submit()
    });

    const acceptSpan = document.getElementById("acceptSpan");
    const acceptInput = document.getElementById("acceptBtn");
    const rejectSpan = document.getElementById("rejectSpan");
    const rejectInput = document.getElementById("rejectBtn");

    acceptSpan.addEventListener("click", function() {
        acceptInput.click();
    });

    rejectSpan.addEventListener("click", function() {
        rejectInput.click();
    });

    const cookiesBar = document.querySelector('.cookie-bar');
    let heroHeight = document.querySelector('.hero-main');
    let heroSHeight = document.querySelector('.hero-secondary');
    
    if (heroHeight) {
        heroHeight = heroHeight.clientHeight
        window.addEventListener('scroll', () => {
            if (window.scrollY >= heroHeight) {
                cookiesBar.classList.add('sticky');
            } else {
                cookiesBar.classList.remove('sticky');
            }
        });
    }

    if (heroSHeight) {
        heroSHeight = heroSHeight.clientHeight
        window.addEventListener('scroll', () => {
            if (scrollY >= heroSHeight) {
                cookiesBar.classList.add('sticky');
            } else {
                cookiesBar.classList.remove('sticky');
            }
        });
    }
}