import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger)

export const footerBehavior = () => {
    const sections = document.querySelectorAll('section');
    const header = document.querySelector(".header");
    const footer = document.querySelector(".footer");
    const footerHeight = footer.clientHeight;
    const heroMain = document.querySelector('.hero-main')
    const heroSecondary = document.querySelector('.hero-secondary');

    if (!heroMain) {
        footer.style.opacity = 1;
    }

    if (sections.length > 0) {
        const viewportWidth = window.innerWidth;

        const lastSection = sections[sections.length - 1];
        
        if (viewportWidth > 1024) {
            lastSection.style.marginBottom = footerHeight + 'px';
            lastSection.classList.add("border-radius-bottom");

            if (heroMain) {
                gsap.to(lastSection, {
                    scrollTrigger: {
                        // markers: true,
                        trigger: lastSection,
                        start: "200% 140px",
                        end: "200% 140px",
                        onEnter: () => {
                            header.style.top = '-150px';
                        },
                        onEnterBack: () => {
                            header.style.top = '0';
                        },
                    }
                });
            }
    
            if (heroSecondary) {
                gsap.to(lastSection, {
                    scrollTrigger: {
                        // markers: true,
                        trigger: lastSection,
                        start: "bottom 116px",
                        end: "bottom 116px",
                        onEnter: () => {
                            header.style.top = '-150px';
                        },
                        onEnterBack: () => {
                            header.style.top = '0';
                        },
                    }
                });
            }
        } else {
            if (heroMain) {
                gsap.to(lastSection, {
                    scrollTrigger: {
                        // markers: true,
                        trigger: lastSection,
                        start: "bottom 116px",
                        end: "bottom 116px",
                        onEnter: () => {
                            header.style.top = '-150px';
                        },
                        onEnterBack: () => {
                            header.style.top = '0';
                        },
                    }
                });
            }
    
            if (heroSecondary) {
                gsap.to(lastSection, {
                    scrollTrigger: {
                        // markers: true,
                        trigger: lastSection,
                        start: "bottom 116px",
                        end: "bottom 116px",
                        onEnter: () => {
                            header.style.top = '-150px';
                        },
                        onEnterBack: () => {
                            header.style.top = '0';
                        },
                    }
                });
            }
        }
    }
}