import { gsap } from "gsap";
import { ScrollTrigger, SplitText } from "gsap/all";

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

export const animations = () => {
    const components = document.querySelectorAll(".--fade-in");

    const heroMain = document.querySelector('.hero-main');
    const heroMainHeight = heroMain?.clientHeight;
    const positionElTop = heroMainHeight ? heroMainHeight + "px" : "top";
    const positionElCenter = heroMainHeight ? (heroMainHeight + heroMainHeight / 2) + "px" : "center";

    components.forEach((component) => {
        const viewportWidth = window.innerWidth;
    
        if (viewportWidth >= 1024) {
            gsap.to(component, {
                scrollTrigger: {
                    // markers: true,
                    trigger: component,
                    start: `${positionElTop} 80%`,
                    end: `${positionElTop} 80%`,
                    once: true,
                    onEnter: () => {
                        gsap.fromTo(
                            component.querySelectorAll(".--cards-animation"),
                            {
                                y: 10,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                opacity: 1,
                                duration: 0.5,
                                stagger: 0.25,
                                ease: "power3.inOut",
                            }
                        );
                    }
                }
            })
        } else {
            gsap.to(component, {
                scrollTrigger: {
                    // markers: true,
                    trigger: component,
                    start: `top 90%`,
                    end: `top 90%`,
                    once: true,
                    onEnter: () => {
                        gsap.fromTo(
                            component.querySelectorAll(".--cards-animation"),
                            {
                                y: 10,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                opacity: 1,
                                duration: 0.5,
                                stagger: 0.25,
                                ease: "power3.inOut",
                            }
                        );
                    }
                }
            })
        }
    })

    components.forEach((component) => {
        const viewportWidth = window.innerWidth;
    
        if (viewportWidth >= 1024) {
            gsap.to(component, {
                scrollTrigger: {
                    // markers: true,
                    trigger: component,
                    start: `${positionElCenter} 90%`,
                    end: `${positionElCenter} 90%`,
                    once: true,
                    onEnter: () => {
                        gsap.fromTo(
                            component.querySelectorAll(".--el-animation"),
                            {
                                y: 10,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                opacity: 1,
                                duration: 0.5,
                                stagger: 0.25,
                                ease: "power3.inOut",
                            }
                        );

                        // text statements
                        const textStatement = new SplitText(component.querySelector(".text-statement"), {
                            type: "lines",
                        });

                        const statementTl = gsap.timeline();

                        textStatement.lines.forEach((line) => {
                            statementTl.from(line, { opacity: 0, duration: 0.3, y: 10, ease: "power3.inOut" });
                        });
                    }
                }
            })
        } else {
            gsap.to(component, {
                scrollTrigger: {
                    // markers: true,
                    trigger: component,
                    start: `top 90%`,
                    end: `top 90%`,
                    once: true,
                    onEnter: () => {
                        gsap.fromTo(
                            component.querySelectorAll(".--el-animation"),
                            {
                                y: 10,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                opacity: 1,
                                duration: 0.5,
                                stagger: 0.25,
                                ease: "power3.inOut",
                            }
                        );

                        // text statements
                        const textStatement = new SplitText(component.querySelector(".text-statement"), {
                            type: "lines",
                        });

                        const statementTl = gsap.timeline();

                        textStatement.lines.forEach((line) => {
                            statementTl.from(line, { opacity: 0, duration: 0.3, y: 10, ease: "power3.inOut" });
                        });
                    }
                }
            })
        }
    })
}