export const handleVideo = () => {
    const videoContainers = document.querySelectorAll('.text-with-media-video-link__container');

    if (videoContainers) {
        videoContainers.forEach((item) => {            
            item.addEventListener('click', () => {
                const enlace = item.querySelector('.video');
                const videoSrc = enlace.getAttribute('src');
                const replaceWatch = videoSrc.replace("watch?v=", "embed/");
                const nuevoEnlace =  replaceWatch + "?autoplay=0";
                
                enlace.setAttribute('src', nuevoEnlace);

                item.querySelector('.video-play').style.opacity = '0';
                setTimeout(() => {
                    item.querySelector('.video-thumbnail').style.display = 'none';
                }, 1000);

                const videoIframe = item.querySelector('.video');

                const currentSrc = videoIframe.getAttribute('src');
                const newSrc = currentSrc.replace('autoplay=0', 'autoplay=1');
                if (currentSrc !== newSrc) {
                    videoIframe.setAttribute('src', newSrc);
                }
            });
        });
    }
}