export function accordionToggle() {

    const visibleParts = document.querySelectorAll(".accordion__visible-part");

    if (visibleParts) {
        visibleParts.forEach(visiblePart => {
            visiblePart.addEventListener("click", function() {
                const panel = this.querySelector(".panel");
                const arrow = this.querySelector(".toggle-arrow");

                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                    arrow.style.transform = "rotate(0deg)";
                    arrow.classList.remove("active")
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                    arrow.style.transform = "rotate(180deg)";
                    arrow.classList.add("active")
                }
            });
        });
    }
}