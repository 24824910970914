import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger)

export const longTextChapters = () => {
    const boxContainer = document.querySelector('.long-text-with-chapters__boxes');
    const boxes = boxContainer?.querySelectorAll('.long-text-with-chapters__content');

    boxes?.forEach((box, index) => {
        gsap.to(box, {
            scrollTrigger: {
                // markers: true,
                trigger: box,
                start: "top 30%",
                end: "bottom 30%",
                onEnter: () => {
                    box.classList.add('active');
                    const anchor = document.querySelector(".chapter-" + (index + 1));
                    anchor?.classList.add('active');
                },
                onEnterBack: () => {
                    box.classList.add('active');
                    const anchor = document.querySelector(".chapter-" + (index + 1));
                    anchor?.classList.add('active');
                },
                onLeave: () => {
                    box.classList.remove('active');
                    const anchor = document.querySelector(".chapter-" + (index + 1));
                    anchor?.classList.remove('active');
                    
                },
                onLeaveBack: () => {
                    box.classList.remove('active');
                    const anchor = document.querySelector(".chapter-" + (index + 1));
                    anchor?.classList.remove('active');
                }
            }
        })
    })
}

export const longTextAccordion = () => {
    const boxContainer = document.querySelector('.long-text-with-accordions__accordion__container');
    const boxes = boxContainer?.querySelectorAll('.long-text-with-accordions__accordion__content');

    boxes?.forEach((box, index) => {
        gsap.to(box, {
            scrollTrigger: {
                trigger: box,
                start: "top 30%",
                end: "bottom 30%",
                onEnter: () => {
                    box.classList.add('active');
                    const anchor = document.querySelector(".chapter-acc-" + (index + 1));
                    anchor?.classList.add('active');
                },
                onEnterBack: () => {
                    box.classList.add('active');
                    const anchor = document.querySelector(".chapter-acc-" + (index + 1));
                    anchor?.classList.add('active');
                },
                onLeave: () => {
                    box.classList.remove('active');
                    const anchor = document.querySelector(".chapter-acc-" + (index + 1));
                    anchor?.classList.remove('active');
                    
                },
                onLeaveBack: () => {
                    box.classList.remove('active');
                    const anchor = document.querySelector(".chapter-acc-" + (index + 1));
                    anchor?.classList.remove('active');
                }
            }
        })
    })
}

export const longTextSticky = () => {
    const sectionC = document.querySelectorAll('.long-text-with-chapters');
    const sectionA = document.querySelectorAll('.long-text-with-accordions');

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if (sectionC.length > 0) {
                    sectionC.forEach(section => {
                        const chaptersContainer = section.querySelector('.long-text-with-chapters__chapters');
                        chaptersContainer.classList.add('visible');
                    })
                }

                if (sectionA.length > 0) {
                    sectionA.forEach(section => {
                        const chaptersContainer = section.querySelector('.long-text-with-accordions__chapters');
                        chaptersContainer.classList.add('visible');
                    })
                }

                observer.unobserve(entry.target);
            }
        });
    }, options);

    if(sectionC) {
        sectionC.forEach(section => {
            observer.observe(section);
        })
    }

    if(sectionA) {
        sectionA.forEach(section => {
            observer.observe(section);
        })
    }
}